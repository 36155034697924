"use strict";

export function init() {
    resizeAreaElements();

    window.addEventListener('resize', el => {
        resizeAreaElements();
    });

    document.querySelectorAll(".js-image-hotspot__area-audio").forEach(el => {
        el.addEventListener("click", e => {
            const id = e.target.getAttribute('data-id');
            const audioId = 'image-hotspot-audio_' + id.split('_')[1] + '_' + id.split('_')[2];
            const audioItem = document.querySelector('audio[data-id="' + audioId + '"]');
            let play = true;
            if (audioItem.currentTime > 0 && audioItem.currentTime < audioItem.duration) {
                play = false
            }
            document.querySelectorAll('audio').forEach(audio => {
                console.log(audio.currentTime);
                console.log(audio.duration);
                audio.pause();
                audio.currentTime = 0;
            });
            if (play)
                document.querySelector('audio[data-id="' + audioId + '"]').play();
        });
    });

    document.querySelectorAll(".js-image-hotspot__card").forEach(el => {
        el.addEventListener('mouseleave', e => {
            hoverElementOut(e);
        });
    });

    document.querySelectorAll(".js-image-hotspot__image-wrapper img").forEach(el => {
        el.addEventListener("click", e => {
            const element = document.querySelector('.js-image-hotspot__card-active');
            element.classList.remove('d-block');
            const arrowLeft = document.querySelector('.js-image-hotspot__arrow-left');
            arrowLeft.classList.remove('d-block');
            const arrowRight = document.querySelector('.js-image-hotspot__arrow-right');
            arrowRight.classList.remove('d-block');
            document.querySelector('[data-active-hotspot="true"]').setAttribute('data-active-hotspot', "false");
        });
    });

    document.querySelectorAll(".js-image-hotspot__area-hover").forEach(el => {
        el.addEventListener('focus', e => {
            hoverElement(e);
        });
        el.addEventListener('focusout', e => {
            hoverElementOut(e);
        });
        el.addEventListener('mouseenter', e => {
            hoverElement(e);
        });
    });
}

function hoverElementOut(e){
    const element = document.querySelector('.js-image-hotspot__card-active');
    console.log(element);
    element.classList.remove('d-block');
    document.querySelector('[data-active-hotspot="true"]').setAttribute('data-active-hotspot', "false");
    const arrowLeft = document.querySelector('.js-image-hotspot__arrow-left');
    arrowLeft.classList.remove('d-block');
    const arrowRight = document.querySelector('.js-image-hotspot__arrow-right');
    arrowRight.classList.remove('d-block');
}

function hoverElement(e) {
    if (e.target.getAttribute('data-active-hotspot') == "true")
        return;
    if (document.querySelectorAll('[data-active-hotspot="true"]').length > 0)
        document.querySelector('[data-active-hotspot="true"]').setAttribute('data-active-hotspot', "false");
    e.target.setAttribute('data-active-hotspot', "true");
    const id = e.target.getAttribute('data-id');
    const wrapperId = "image-hotspot-image-wrapper-" + id.split('_')[1];
    const arrowSpacer = 15;
    const arrowSpacerTop = 25;
    const arrowHeight = 20;
    const item = document.querySelector('.js-image-hotspot__overlay-area[data-id="' + id + '"]');
    const itemWidth = item.clientWidth;
    const itemHeight = item.clientHeight;
    const html = item.innerHTML;
    const t = parseFloat(e.target.getAttribute('data-calc-top'));
    const l = parseFloat(e.target.getAttribute('data-calc-left'));
    const w = parseFloat(e.target.getAttribute('data-calc-width'));
    const h = parseFloat(e.target.getAttribute('data-calc-height'));
    const wrapperWidth = document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').clientWidth;
    const wrapperHeight = document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').clientHeight;
    let cardTop = 0;
    let cardLeft = 0;
    let arrowPositionTop = 0;
    let arrowPositionleft = 0;

    const arrowLeft = document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').querySelector('.js-image-hotspot__arrow-left');
    arrowLeft.classList.remove('d-block');

    const arrowRight = document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').querySelector('.js-image-hotspot__arrow-right');
    arrowRight.classList.remove('d-block');

    arrowPositionTop = (h / 2) + t - (arrowHeight / 2);

    if (wrapperWidth > (itemWidth + l + w + arrowSpacer)) {
        cardLeft = l + w + arrowSpacer;
        arrowPositionleft = w + l + 1 ;
        arrowLeft.classList.add('d-block');
    }
    else {
        cardLeft = l - arrowSpacer - itemWidth;
        arrowPositionleft = l - arrowSpacer - 1;
        arrowRight.classList.add('d-block');
    }
    if (wrapperHeight > (itemHeight + t + h)) {
        cardTop = (h / 2) + t - arrowSpacerTop;
    }
    else {
        cardTop = (h / 2) + t + (-1 * (itemHeight + t + h - wrapperHeight));
    }

    document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').querySelector('.js-image-hotspot__card-body').innerHTML = html;
    document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').querySelector('.js-image-hotspot__card').classList.add('d-block', 'js-image-hotspot__card-active');
    document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').querySelector('.js-image-hotspot__card').style.left = cardLeft + 'px';
    document.querySelector('.js-image-hotspot__image-wrapper[data-id="' + wrapperId + '"]').querySelector('.js-image-hotspot__card').style.top = cardTop + 'px';
    arrowRight.style.top = arrowPositionTop + 'px';
    arrowLeft.style.top = arrowPositionTop + 'px';
    arrowRight.style.left = arrowPositionleft + 'px';
    arrowLeft.style.left = arrowPositionleft + 'px';
}

function resizeAreaElements() {
    if (document.getElementsByClassName("js-image-hotspot__wrapper").length === 0)
        return;
    document.querySelectorAll(".js-image-hotspot__wrapper").forEach(el => {
        const img = el.querySelector(".js-image-hotspot__image-wrapper img");
        const borderSize = 10;
        let scaledH = img.height / img.width;
        let scalingFactor = img.width / 1000;
        let scalingFactorH = scaledH * scalingFactor;
        el.querySelectorAll(".js-image-hotspot__area-select").forEach(item => {
            let t = (item.getAttribute('data-top') * 10 + 15) * scalingFactorH;
            let l = (item.getAttribute('data-left') * 10 + 10) * scalingFactor;
            let w = (item.getAttribute('data-width') * 10 - 20) * scalingFactor;
            let h = (item.getAttribute('data-height') * 10 - 30) * scalingFactorH;
            item.style.top = t + 'px';
            item.style.left = l + 'px';
            item.style.width = w + 'px';
            item.style.height = h + 'px';
            item.setAttribute('data-calc-top', t);
            item.setAttribute('data-calc-left', l);
            item.setAttribute('data-calc-width', w);
            item.setAttribute('data-calc-height', h);
        });
    });
}