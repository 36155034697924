"use strict";

export function init () {
    document.querySelectorAll(".js-youtube-inline__wrapper").forEach(el => el.addEventListener('click', event => {
        event.target.classList.add('youtube-inline__wrapper-active');
        if (event.target.querySelector('iframe')) {
            event.target.querySelector('iframe').src += "&autoplay=1";
        }
        event.target.querySelector('.js-youtube-inline__preview').classList.add('d-none');
    }));
}