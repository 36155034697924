import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn, find, trigger, findAllIn} from "@elements/dom-utils";
import Popover from 'bootstrap/js/dist/popover';

export function init() {
    onFind('.js-hotspot__toggle', function (hotspotButton) {
        let container = closest('.js-hotspot-container', hotspotButton);
        let hotspot = closest('.js-hotspot', hotspotButton);
        let detail = findIn('.js-hotspot__detail', hotspot);

        const popover = new Popover(hotspotButton, {
            trigger: 'manual',
            html: true,
            content: detail,
            placement: 'bottom',
            offset: function (instance) {
                let offset = [(instance.popper.width/2), 10];

                if (instance.reference.x + instance.popper.width >= container.getBoundingClientRect().width + container.getBoundingClientRect().left) {
                    offset = [-(instance.popper.width/2), 10];
                }

                return offset
            }
        });

        on('click', function () {
            if(!hasClass('is-open', hotspot)) {
                addClass('is-open', hotspot);

                popover.show();
                window.addEventListener("click", function _listener(evt) {
                    if (!hotspot.contains(evt.target)) {
                        removeClass('is-open', hotspot);
                        popover.hide();

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);
            } else {
                popover.hide();
                removeClass('is-open', hotspot);
            }

        }, hotspotButton);
    });

    onFind('.js-hotspot__detail-trigger-tab', (triggerTab) => {
        on('click', (evt) => {
            let target = find(triggerTab.getAttribute('data-target')),
                scrollTarget = find(triggerTab.getAttribute('data-scroll-target'));

            if (target) {
                if (target.tagName.toLowerCase() === 'input') {
                    target.checked = true;
                    trigger('change', target);
                } else {
                    trigger('click', target);
                }
            }

            window.scrollTo({
                top: scrollTarget.offsetTop - 140,
                behavior: 'smooth'
            });
        }, triggerTab);
    });
}