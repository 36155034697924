"use strict";
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-scroll-slider-xal', (el) => {
        setArrowPosition(el)

        window.addEventListener('resize', function() {
            setArrowPosition(el)
        });
    });

    onFind('.js-scroll-slider-xal-with-effect', (el) => {
        setArrowPosition(el)

        window.addEventListener('resize', function() {
            setArrowPosition(el)
        });
    });

    // window.onload = function () {
    //     document.querySelectorAll('.js-scroll-slider-xal').forEach(el => {
    //         const elements = el.getElementsByTagName('img');
    //         if (!elements.length > 0)
    //             return;
    //         const firstImgHeight = el.getElementsByTagName('img')[0].clientHeight;
    //         el.querySelectorAll('.scroll-slider__arrow').forEach(e => {
    //             e.style.top = firstImgHeight / 2 + "px";
    //         });
    //     });
    //
    //     document.querySelectorAll('.js-scroll-slider-xal-with-effect').forEach(el => {
    //         const elements = el.getElementsByTagName('img');
    //         if (!elements.length > 0)
    //             return;
    //         const firstImgHeight = el.getElementsByTagName('img')[0].clientHeight;
    //         el.querySelectorAll('.scroll-slider__arrow').forEach(e => {
    //             e.style.top = firstImgHeight / 2 + "px";
    //         });
    //     });
    // }
}

function setArrowPosition (el) {
    const elements = el.getElementsByTagName('img');
    if (!elements.length > 0)
        return;
    const firstImgHeight = el.getElementsByTagName('img')[0].clientHeight;
    el.querySelectorAll('.scroll-slider__arrow').forEach(e => {
        e.style.top = firstImgHeight / 2 + "px";
    });

}