import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass, closest, on} from "@elements/dom-utils";
import {add} from '@elements/scroll-animations';

export function init() {
    onFind('.js-scroll-video__media', function (video) {
        let container = closest('.js-scroll-video', video),
            // lower numbers = faster playback
            playback = 450;

        if (matchMedia('(max-width: 767px)').matches) {
            addClass('is-finished', container);
        } else {
            video.currentTime = 0.01;

            video.addEventListener('loadedmetadata', function() {
                container.style.height = Math.floor(video.duration) * playback + "px";
            });

            if (video.duration) {
                container.style.height = Math.floor(video.duration) * playback + "px";
            }

            let fadeInElements = [];
            fadeInElements.push(video);
            add(
                fadeInElements,
                calculateVideoProgress,
                setVideoProgress
            );
        }
    });
}

function calculateVideoProgress(element) {
    let container = closest('.js-scroll-video', element);

    const distanceFromTop = window.scrollY + container.getBoundingClientRect().top;
    const rawPercentScrolled = (window.scrollY - distanceFromTop) / (container.scrollHeight - window.innerHeight - 100);
    const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1);

    return {
        currentTime: element.duration * percentScrolled
    }

}

function setVideoProgress(element, progress) {
    if (progress.currentTime) {
        element.currentTime = progress.currentTime;
    }

    let container = closest('.js-scroll-video', element);
    if (element.duration === progress.currentTime) {
        addClass('is-finished', container);
    } else {
        removeClass('is-finished', container);
    }
}