"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, find} from "@elements/dom-utils";
import {showNotification, clearAll} from '@elements/alert-notification';
import {translate} from '@elements/translations';

export function init() {
    onFind('.js-copy-to-clipboard', function (btn) {
        on('click', function () {
            let target = find(btn.getAttribute('data-target'));

            let clipboardText = btn.getAttribute('data-copy-content');

            if (target) {
                clipboardText = target.textContent;
            }

            navigator.clipboard.writeText(clipboardText);

            clearAll();

            let successTitle = translate('copy-notification.title');
            let successText = translate('copy-notification.text');

            if (btn.getAttribute('data-copy-notification-title')) {
                successTitle = btn.getAttribute('data-copy-notification-title');
            }
            if (btn.getAttribute('data-copy-notification-text')) {
                successText = btn.getAttribute('data-copy-notification-text');
            }

            showNotification({
                type: 'info',
                title: successTitle,
                content: successText
            })
        }, btn);
    });
}