import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn} from "@elements/dom-utils";

export function init(){
   onFind('.js-ajax-form', function (element){
       let offset = matchMedia('(max-width: 767px)').matches ? 70 : 120;

       let scrollTo = element.getBoundingClientRect().top + window.scrollY - offset;

       if (element.getAttribute('data-ajax-form-scroll-result')) {
           console.log(findIn('.js-ajax-form__result', element).offsetTop);
           scrollTo = findIn('.js-ajax-form__result', element).getBoundingClientRect().top + window.scrollY - offset;
       }

       on('success.ajax-form', (evt) => {
           window.scrollTo({
               top: scrollTo,
               behavior: 'smooth'
           });
       }, element);
   });
}