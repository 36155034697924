import {onFind} from "@elements/init-modules-in-scope";
import Popover from 'bootstrap/js/dist/popover';
import {on, hasClass, addClass, removeClass, findIn, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-popover', function (element) {
        let toggle = findIn('.js-popover__toggle', element);
        let content = findIn('.js-popover__content', element);

        const popover = new Popover(toggle, {
            trigger: 'manual',
            html: true,
            content: content,
            placement: 'top',
            delay: {
                "show": 500,
                "hide": 500
            },
        });

        if (matchMedia('(max-width: 767px)').matches) {
            on('click', function () {
                if(!hasClass('is-open', element)) {
                    addClass('is-open', element);
                    popover.show();

                    window.addEventListener("click", function _listener(evt) {
                        if (!element.contains(evt.target)) {
                            removeClass('is-open', element);
                            popover.hide();
                            window.removeEventListener("click", _listener, true);
                        }
                    }, true);
                } else {
                    popover.hide();
                    removeClass('is-open', element);
                }
            }, toggle);
        } else {
            on('mouseover', function () {
                addClass('is-open', element);
                popover.show();
            }, toggle);
            on('mouseout', function () {
                removeClass('is-open', element);
                popover.hide();
            }, toggle)
        }
    });
}